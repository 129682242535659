import { useEffect, useRef, useCallback } from "react"
import type { ComponentType } from "react"

declare global {
    interface Window {
        checkoutPage: any
    }
}

function useEmbed() {
    useEffect(() => {
        if (window?.checkoutPage) return

        const script = document.createElement("script")

        script.src = "https://checkoutpage.co/js/overlay.js"
        script.async = true

        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [])

    return null
}

export function withCheckoutPopup(Component): ComponentType {
    return (props: { className }) => {
        useEmbed()

        props.className += " cp-button"

        return <Component {...props} />
    }
}
